import React from 'react';
import PropTypes from 'prop-types';
import 'styles/components/FeatureGrid.scss';
import { btnOnClick } from '../../utils/contentProvider/function';

const subTextData = [
  {
    productType: 'website-feedback-tool',
    subtext: 'Start commenting and editing on live sites right away!',
    btnID: 'get-started-free-wft',
  },
  {
    productType: 'online-pdf-annotator-markup-software',
    subtext: 'Start reviewing your PDF files right away!!',
    btnID: 'get-started-free-pdf',
  },
  {
    productType: 'bug-tracking-tool',
    subtext: 'Resolve bugs with your team within minutes',
    btnID: '',
  },
  {
    productType: 'video-annotation',
    subtext: '',
    btnID: 'get-started-for-free-vat-fold7',
  },
];
const FeaturesGrid = ({
  data,
  productType,
  downloadApp,
  toggleDownloadAppModal,
}) => (
  <section className="feature-grid" id="features">
    <div className="container">
      <div className="section-head">
        <h2 style={{ maxWidth: 700 }}>
          Packed with features for your most productive team work yet
        </h2>
      </div>
      <div className="features">
        {data.map((feat) => (
          <div className="feature-single" key={feat.heading}>
            <div className="cover-img">
              <img
                src={feat.cover}
                alt={`${productType.replaceAll('-', ' ')} ${feat.heading}`}
                loading="lazy"
              />
            </div>
            <div className="feature-content">
              <h3>
                {feat.heading}
                {feat.heading.includes('console') ? (
                  <span className="status-tag coming-soon">Coming Soon</span>
                ) : (
                  ''
                )}
              </h3>

              <p>{feat.description}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="flex column align-items-center justify-content-center">
        {downloadApp ? (
          <>
            <div className="hidden-web">
              <a
                href="https://play.google.com/store/apps/details?id=com.ruttl.app"
                style={{ backgroundColor: '#150546', border: 'none' }}
                className="button">
                <img
                  src="/assets/img/play-store-logo.png"
                  alt="playstore logo"
                />
                <span style={{ marginLeft: 10 }}>Download App</span>
              </a>
            </div>
            <button
              type="button"
              onClick={toggleDownloadAppModal}
              style={{ backgroundColor: '#150546', border: 'none' }}
              id="download-app-maf-prefooter"
              className="button hidden-mob">
              <img src="/assets/img/play-store-logo.png" alt="playstore logo" />
              <span style={{ marginLeft: 10 }}>Download App</span>
            </button>
          </>
        ) : (
          <button
            type="button"
            className="button"
            id={
              subTextData.filter(
                (value) => value.productType === productType,
              )[0]?.btnID || ''
            }
            onClick={btnOnClick({
              to: 'https://web.ruttl.com/signup',
            })}>
            Get started for free
          </button>
        )}

        {subTextData
          .filter((value) => value.productType === productType)
          .map(({ subtext }) => (
            <p className="sub-text-small">{subtext}</p>
          ))}
      </div>
    </div>
  </section>
);

FeaturesGrid.propTypes = {
  data: PropTypes.array,
  productType: PropTypes.string,
  downloadApp: PropTypes.bool,
  toggleDownloadAppModal: PropTypes.func,
};
export default FeaturesGrid;
